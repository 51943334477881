import { runtimeProcessEnv } from './helpers'
import { Endpoint, getFullEndpoint, requestHeaders } from '@kalkul/components/services/api'
import { fetcher } from '@kalkul/components/services/api'
import { Token } from 'next-auth'
import { sessionAccessor } from '../components/session-container'
import { sleep } from '@kalkul/components/services/helpers'
import { getSession } from 'next-auth/react'

interface RequestWithToken {
    token: Token | undefined
    body?: BodyInit
}

const processEnv = runtimeProcessEnv()

export const getEndpoint = ((hostname: string) => (endpoint: Endpoint) => {
    return getFullEndpoint(hostname, endpoint)
})(processEnv.API_URL)

const NEW_SESSION_WAIT_INTERVAL = 2000

export const fetcherWithSession = async (endpoint: string) => {
    const session = await getSession()

    if (!session) {
        return Promise.resolve({
            ok: false,
            status: 418,
            statusText: 'No valid session',
        })
    }

    const propsWithAccessToken: RequestInit = {
        headers: {
            'Authorization': `Bearer ${session.token.access_token}`,
            ...requestHeaders.JSON,
        },
    }

    let response = await fetcher(endpoint, propsWithAccessToken)

    // In case we get 401, we trigger the NextAuth session callback and repeat the request
    if (response.status === 401 && endpoint !== `${getEndpoint('auth-refresh')}/${session.token.refresh_token}`) {
        await sessionAccessor.refresh()
        await sleep(NEW_SESSION_WAIT_INTERVAL)

        response = await fetcherWithSession(endpoint)
    }

    return response
}

const requestWithToken = (endpoint: string, method = 'GET') => async ({ token, body }: RequestWithToken) => {
    if (!token) {
        return Promise.resolve({
            ok: false,
            status: 418,
            statusText: 'No valid token',
        })
    }

    const props: RequestInit = {
        method,
        headers: {
            'Authorization': `Bearer ${token.access_token}`,
            ...requestHeaders.JSON,
        },
    }

    if (body) {
        props.body = body
    }

    let response = await fetcher(endpoint, props)

    /* In case we get 401 for a non-auth-refresh request,
     * we trigger the NextAuth session callback and repeat the request
    */
    if (response.status === 401 && endpoint !== `${getEndpoint('auth-refresh')}/${token.refresh_token}`) {
        await sessionAccessor.refresh()
        await sleep(NEW_SESSION_WAIT_INTERVAL)

        const sessionNew = await sessionAccessor.refresh()

        response = await requestWithToken(endpoint, method)({ token: sessionNew?.token, body })
    }

    return response
}

export const auraSignOut = requestWithToken(getEndpoint('auth'), 'DELETE')
export const refreshToken = (refreshToken: string) => requestWithToken(`${getEndpoint('auth-refresh')}/${refreshToken}`)
export const requestNewPassword = (email: string) => fetcher(`${getEndpoint('password-request')}/${email}`, { headers: { ...requestHeaders.JSON }})

export const getUserPublicProfiles = (ids: string[]) => () => fetcher(getEndpoint('profile-public'), { method: 'POST', body: JSON.stringify(ids), headers: { ...requestHeaders.JSON } })

export const updateKalkul = (id: string) => requestWithToken(`${getEndpoint('kalkuls')}/${id}`, 'PATCH')
export const createKalkul = () => requestWithToken(`${getEndpoint('kalkuls')}`, 'POST')
export const publishKalkul = (id: string) => requestWithToken(`${getEndpoint('kalkuls')}/release/${id}`, 'POST')
export const archiveKalkul = (id: string) => requestWithToken(`${getEndpoint('kalkuls')}/${id}`, 'DELETE')
export const uploadKalkulAsset = (id: string) => requestWithToken(`${getEndpoint('kalkuls')}/${id}/upload`, 'POST')
export const updateNodeSoundscape = (id: string) => requestWithToken(`${getEndpoint('soundscape')}/${id}`, 'PATCH')
export const updateNodeVersi = () => requestWithToken(`${getEndpoint('versi')}`, 'PATCH')
export const deleteNodeVersi = (id: string) => requestWithToken(`${getEndpoint('versi')}/${id}`, 'DELETE')
export const createNodeVersi = (kalkulId: string) => requestWithToken(`${getEndpoint('kalkuls')}/${kalkulId}/node`, 'POST')
//export const createNodeArrayVersi = (kalkulId: string) => requestWithToken(`${getEndpoint('kalkuls')}/${kalkulId}/nodes`, 'POST')
export const addKalkulCollaborator = (kalkulId: string, username: string) => requestWithToken(`${getEndpoint('kalkuls')}/${kalkulId}/collaborators/${username}`, 'POST')
export const removeKalkulCollaborator = (kalkulId: string, username: string) => requestWithToken(`${getEndpoint('kalkuls')}/${kalkulId}/collaborators/${username}`, 'DELETE')
export const updateAppClip = (id: string) => requestWithToken(`${getEndpoint('appclip')}/${id}`, 'PATCH')
export const createAppClip = (kalkulId: string) => requestWithToken(`${getEndpoint('kalkuls')}/${kalkulId}/appclips`, 'POST')

export const uploadUserAvatar = () => requestWithToken(`${getEndpoint('profile')}/avatar/upload`, 'POST')
export const updateUser = () => requestWithToken(`${getEndpoint('profile')}/web`, 'PATCH')
export const testUsername = (username: string) => requestWithToken(`${getEndpoint('username')}/${username}`)
export const updateUsername = () => requestWithToken(getEndpoint('username'), 'POST')

export const getPrivateAssetDirectLink = (path: string) => requestWithToken(`${getEndpoint('private-asset')}/${path}`)
