import { runtimeProcessEnv } from '../../services/helpers'
import KalkulLogo from '@kalkul/components/images/kalkul-logo'
import { useTranslation } from 'next-i18next'
import styles from './styles.module.scss'
import COLORS from '@kalkul/components/styles/exports.colours.module.scss'

const processEnv = runtimeProcessEnv()

const CopyrightNote: React.FC = () => {
    const { t } = useTranslation('common')

    return (
        <div className={styles.wrapper}>
            <KalkulLogo fill={COLORS.white} />
            <div><i>{t('versi')}</i></div>
            <div className={styles.sub}>{/* t('creatorTools') */}v{processEnv.APP_VERSION}</div>
        </div>
    )
}

export default CopyrightNote
