import getConfig from 'next/config'

export const runtimeProcessEnv = () => {
    const { publicRuntimeConfig } = getConfig()

    return publicRuntimeConfig as App.ProcessEnv
}

export const readFile = (file: File): Promise<FileReader['result']> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.onerror = reject
        fileReader.onload = () => resolve(fileReader.result)

        fileReader.readAsDataURL(file)
    })
}

export const readImage = async (file: File): Promise<HTMLImageElement | null> => {
    return new Promise(async (resolve, reject) => {
        const imageFile = await readFile(file)
        const imageReader = new Image()

        if (!imageFile || ArrayBuffer.isView(imageFile)) {
            reject()

            return
        }

        imageReader.onerror = reject
        imageReader.onload = () => resolve(imageReader)

        imageReader.src = imageFile as string
    })
}

export const readVideo = async (file: File): Promise<HTMLVideoElement | null> => {
    return new Promise(async (resolve, reject) => {
        const videoFileUrl = URL.createObjectURL(file)
        const videoReader = document.createElement('video')

        if (!videoFileUrl) {
            reject()

            return
        }

        videoReader.onerror = reject
        videoReader.onloadedmetadata = () => resolve(videoReader)

        videoReader.src = videoFileUrl
    })
}

export const traverseJSONParse = (obj: Record<string, any>, callback: (key: string, value: any) => any) => {
    return JSON.parse(JSON.stringify(obj), callback)
}

export const regularExpressions = {
    latitude: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
    longitude: /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
}

export const validationErrorAdd = (validation: Record<string, string[]>, type: string, error: string) => {
    if (type in validation === false) {
        validation[type] = []
    }

    validation[type].push(error)

    return validation
}
