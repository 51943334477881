import { KalkulInc } from '../../types/Kalkul'
import styles from './styles.module.scss'

const KalkulLogo = ({ fill }: KalkulInc.Assets.SVGImage) => (
    <svg viewBox="0 0 622.6 66.9">
        <g className={styles.group} {...(fill ? { style: { fill }} : null)}>
            <path d="M 538.5 54.9 L 538.5 6 C 538.5 2.7 535.8 0 532.5 0 L 511.8 0 C 508.5 0 505.8 2.7 505.8 6 L 505.8 54.9 L 444.1 54.9 C 443.3 54.9 442.5 54.6 441.9 54.1 L 417.1 33.4 L 457.4 0 L 438.7 0 L 409 24.7 C 406.4 26.9 404.9 30.1 404.9 33.5 C 404.9 36.9 406.4 40.1 409 42.3 L 434.3 63.4 C 437.1 65.7 440.6 67 444.1 67 L 505.8 67 C 512.4 67 517.8 61.6 517.8 55 L 517.8 12 L 526.5 12 L 526.5 54.9 C 526.5 61.5 531.9 66.9 538.5 66.9 L 622.6 66.9 L 622.6 54.9 L 538.5 54.9 L 538.5 54.9 Z" />
            <path d="M 406.8 0 L 385.3 17.9 L 332.8 17.9 L 332.8 29.9 L 385.3 29.9 C 388.1 29.9 390.8 28.9 393 27.1 L 425.7 0 L 406.8 0 L 406.8 0 Z" />
            <path d="M 426.1 66.9 L 393 39.4 C 390.8 37.6 388.1 36.6 385.3 36.6 L 332.8 36.6 L 332.8 48.6 L 385.3 48.6 L 407.3 66.9 L 426.1 66.9 L 426.1 66.9 Z" />
            <rect x="0" y="0" width="12" height="66.9" />
            <path d="M 91.9 0 L 70.4 17.9 L 17.9 17.9 L 17.9 29.9 L 70.4 29.9 C 73.2 29.9 75.9 28.9 78.1 27.1 L 110.7 0 L 91.9 0 L 91.9 0 Z" />
            <path d="M 91.9 66.9 L 70.4 49 L 17.9 49 L 17.9 37 L 70.4 37 C 73.2 37 75.9 38 78.1 39.8 L 110.8 66.9 L 91.9 66.9 L 91.9 66.9 Z" />
            <rect x="211.5" y="0" width="12" height="46.3" />
            <path d="M 315 0 L 315 54.9 L 284.1 54.9 L 298.5 66.9 L 327 66.9 L 327 0 L 315 0 Z" />
            <path d="M 146.3 43.2 L 127.9 43.2 L 155.7 66.9 L 174.1 66.9 L 146.3 43.2 Z" />
            <path d="M 461.4 23.7 L 489 0 L 470.6 0 L 443 23.7 L 461.4 23.7 Z" />
            <path d="M 202.9 54.9 L 202.9 12 C 202.9 5.4 197.5 0 190.9 0 L 129.2 0 C 125.6 0 122.1 1.3 119.4 3.6 L 94.1 24.7 C 91.5 26.9 90 30.1 90 33.5 C 90 36.9 91.5 40.1 94.1 42.3 L 123.7 67 L 142.4 67 L 102.2 33.5 L 127 12.8 C 127.6 12.3 128.4 12 129.2 12 L 190.9 12 L 190.9 54.9 C 190.9 61.5 196.3 66.9 202.9 66.9 L 286 66.9 L 271.6 54.9 L 202.9 54.9 L 202.9 54.9 Z" />
        </g>
    </svg>
)

export default KalkulLogo
