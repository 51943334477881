enum Endpoints {
    'kalkuls' = 'kalkuls',
    'soundscape' = 'soundscape',
    'versi' = 'versi',

    'auth' = 'auth/sign/web',
    'auth-refresh' = 'auth/refresh',

    'users' = 'users',
    'profile' = 'users/profile',
    'creations' = 'users/creations',
    'password-reset' = 'users/password/reset',
    'password-request' = 'users/retrieve',
    'username' = '/users/profile/name',

    'profile-public' = 'users/public/profiles',
    'appclip' = 'appclip',
    'appclips' = 'appclips',

    'private-asset' = 'asset-url',
}

export interface PaginatedResponse<T extends {}> {
    items: T[],
    metadata: {
        page: number
        per: number
        total: number
    }
}

export type Endpoint = keyof typeof Endpoints

const getEndpoint = (endpoint: Endpoint) => {
    return Endpoints[endpoint]
}

export const getFullEndpoint = (hostname: string, endpoint: Endpoint) => {
    return `${hostname}/${getEndpoint(endpoint)}`
}

export const requestHeaders = {
    JSON: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
}

//TODO: add safechecks to fetcher with ability to type responses

export const fetcher = async (url: string, props?: RequestInit) => {
    let response: Response

    try {
        response = await fetch(url, props)
    } catch (error: any) {
        return Promise.resolve({
            ok: false,
            status: error?.toString().split(':')[0],
            statusText: error?.message,
        })
    }

    // Test if the content is JSON and has data

    const isJSON =
        response.headers.get('content-type')?.includes('application/json') &&
        parseInt(response.headers.get('content-length') || '0', 10) > 0

    if (response.ok) {
        if (isJSON) {
            return response.json()
        } else {
            return response.text()
        }
    } else {
        let body

        if (isJSON) {
            body = await response.json()
        } else {
            body = await response.text()
        }

        if (body && body.hasOwnProperty('ok')) {
            return Promise.resolve(body)
        }

        try {
            body = JSON.parse(body)
        } catch (e) {}

        return Promise.resolve({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            body,
        })
    }
}

export const isResponseSuccessful = (response: any): boolean => {
    return !response.hasOwnProperty('ok') || response.ok
}
